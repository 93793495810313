'use client';

import { FC } from 'react';
import { Player } from '@spikemark/rest-api';
import { Mascot, MascotProps, variants } from './mascot';

type AvatarVariant = 'medium' | 'large' | 'xlarge';

const schoolLogoSizeVariants: Record<AvatarVariant, MascotProps['variant']> = {
  xlarge: 'medium',
  large: 'badge',
  medium: 'small',
};

export type AvatarProps = Pick<
  Player,
  'userPicture' | 'playerName' | 'schoolLogoThumbnail' | 'schoolName'
> & {
  variant?: AvatarVariant;
};

export const Avatar: FC<AvatarProps> = ({
  playerName,
  schoolName,
  schoolLogoThumbnail,
  userPicture,
  variant = 'medium',
}) => {
  const { width, height } = variants[variant];

  return (
    <div className="relative leading-none" style={{ width, height }}>
      <Mascot variant={variant} path={userPicture} alt={playerName} displayMode="player" />
      <Mascot
        variant={schoolLogoSizeVariants[variant]}
        path={schoolLogoThumbnail}
        alt={schoolName}
        className="absolute bottom-1.5 -right-3.5"
        displayMode="team"
        border
      />
    </div>
  );
};
